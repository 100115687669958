import './css/main.css'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize, faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import NewPage from './NewPage'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import { SyncLoader } from "react-spinners";
import { removeZeros, changeNum } from './common/function'

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function Settlement() {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    let SelectType = useSelector((state) => { return state.SelectType.value });

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    useEffect(() => {
        if (SelectType != 0) navigate(`/dashboard`)
    }, [SelectType])

    const location = useLocation();
    const [tab, setTab] = useState(location.state === null ? 0 : location.state.num)

    useEffect(() => {
        if (location.state === null) setTab(0)
        else setTab(location.state.num)
    }, [location])

    return (
        <div className='main'>
            <Sidebar page={tab + 9} />
            {
                tab === 0
                    ?
                    <Settlement_Bn tab={tab} />
                    :
                    tab === 1
                        ?
                        <Settlement_OKX tab={tab} />
                        :
                        <Settlement_Bybit tab={tab} />
            }
        </div>
    )
}

function Settlement_Bn({ tab }) {
    const [page, setPage] = useState(1)
    const [dateBtn, setDateBtn] = useState(0)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [settlement_bn, setSettlement_bn] = useState([])
    const [settlement_pre_bn, setSettlement_pre_bn] = useState([])
    const [spinner, setSpinner] = useState(false)

    const [total, setTotal] = useState([0, 0, 0, 0, 0])
    const [pre_total, setPre_Total] = useState([0, 0, 0, 0, 0])

    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    useEffect(() => {
        onSpinner()
        Settlement_Bn()
    }, [selectedDate, selectedDate1])

    const Settlement_Bn = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.rf-rev.com/api/v1/bn/settlement/history`, {
                params: {
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1),
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setSettlement_bn(tmp.curData)
                    setSettlement_pre_bn(tmp.preData)
                }
                setTimeout(() => {
                    setSpinner(false)
                }, 500)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        let v = 0
        let f = 0
        let c = 0
        let sr = 0
        let n = 0

        if (settlement_bn && settlement_bn.length > 0) {
            for (let i = 0; i < settlement_bn.length; i++) {
                let tmp = settlement_bn[i]
                v += Number(tmp?.volume ?? 0)
                f += Number(tmp?.fee ?? 0)
                c += Number(tmp?.commission ?? 0)
                sr += Number(tmp?.selferra ?? 0) + Number(tmp?.referral ?? 0)
                n += Number(tmp?.net_profit ?? 0)
            }
            setTotal([v, f, c, sr, n])
        }
    }, [settlement_bn])

    useEffect(() => {
        let v = 0
        let f = 0
        let c = 0
        let sr = 0
        let n = 0

        if (settlement_pre_bn && settlement_pre_bn.length > 0) {
            for (let i = 0; i < settlement_pre_bn.length; i++) {
                let tmp = settlement_pre_bn[i]
                v += Number(tmp?.volume ?? 0)
                f += Number(tmp?.fee ?? 0)
                c += Number(tmp?.commission ?? 0)
                sr += Number(tmp?.selferral ?? 0) + Number(tmp?.referral ?? 0)
                n += Number(tmp?.net_profit ?? 0)
            }
            setPre_Total([v, f, c, sr, n])
        }
    }, [settlement_pre_bn])

    let slice_data = settlement_bn.slice((page - 1) * 15, (page) * 15)

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        if (settlement_bn) {
            for (let i = 0; i < settlement_bn.length; i++) {
                let t = settlement_bn[i]
                let date = t.date.split('T')
                arr.push({
                    "Date": date ? date[0] : t.date,
                    "Volume": t.volume,
                    "Fee": t.fee,
                    "Commission": t.commission,
                    "Self": t.selferral,
                    "Referral": t.referral,
                    "NetProfit": t.net_profit,
                })
            }
        }

        Excel(arr, "Binance")
    }

    return (
        <div className={`main-box ${darkname}`}>
            <div className={`withdraw-1 ${darkname}`}>
                <div style={{ width: "56px", fontWeight: "bold" }}>{tab === 0 ? "Binance" : tab === 1 ? "OKX" : "Bybit"}</div>
                <div className={`modal-3-1-left ${darkname}`}>
                    <div className={dateBtn === 0 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(0)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => { setSelectedDate(date); setSelectedDate1(date) }}
                        />
                    </div>
                    <div className={dateBtn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(1);
                        setSelectedDate(new Date((new Date()).getTime())); setSelectedDate1(new Date())
                    }}>Today</div>
                    <div className={dateBtn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(2);
                        setSelectedDate(new Date(new Date().getTime() - 604800000)); setSelectedDate1(new Date())
                    }}>7 Days</div>
                    <div className={dateBtn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(3);
                        setSelectedDate(new Date(new Date().getTime() - 2592000000)); setSelectedDate1(new Date())
                    }}>30 Days</div>
                    <div className={dateBtn === 4 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(4);
                        setSelectedDate(new Date(new Date().getTime() - 7776000000)); setSelectedDate1(new Date())
                    }}>90 Days</div>
                    <div className={dateBtn === 5 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(5)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                        <div>~</div>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={selectedDate} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate1}
                            onChange={(date) => setSelectedDate1(date)}
                        />
                    </div>
                </div>
            </div>
            <div className={`reward-box ${darkname}`}>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Volume (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[0]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[0] - pre_total[0] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[0] - pre_total[0] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[0] - pre_total[0]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Fee (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[1]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[1] - pre_total[1] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[1] - pre_total[1] >= 0 ? faCaretUp : faCaretDown} style={{ paddingDown: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[1] - pre_total[1]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Commission (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[2]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[2] - pre_total[2] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[2] - pre_total[2] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[2] - pre_total[2]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Self+Referral</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[3]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[3] - pre_total[3] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[3] - pre_total[3] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[3] - pre_total[3]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Net Profit</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[4]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[4] - pre_total[4] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[4] - pre_total[4] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[4] - pre_total[4]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tradingType-1'>
                <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
            </div>
            <div className='tradingType-2'>
                <div className={`table-head ${darkname}`}>
                    <div>Date</div>
                    <div>Volume</div>
                    <div>Fee</div>
                    <div>Commission</div>
                    <div>Self</div>
                    <div>Referral</div>
                    <div>Net Profit</div>
                </div>
                <div className='table-body' style={{ minHeight: "600px" }}>
                    {
                        spinner
                            ?
                            <div className='spinner-modal'>
                                <SyncLoader color="#F5AC1D" />
                            </div>
                            :
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <SettlementItem key={i} item={a} darkname={darkname} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={settlement_bn} page={page} setPage={setPage} maxFirstPage={15} />
                                </>

                    }
                </div>
            </div>
        </div>
    )
}

function Settlement_OKX({ tab }) {
    const [page, setPage] = useState(1)
    const [dateBtn, setDateBtn] = useState(0)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [settlement_okx, setSettlement_okx] = useState([])
    const [settlement_pre_okx, setSettlement_pre_okx] = useState([])
    const [spinner, setSpinner] = useState(false)

    const [total, setTotal] = useState([0, 0, 0, 0, 0])
    const [pre_total, setPre_Total] = useState([0, 0, 0, 0, 0])

    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    useEffect(() => {
        onSpinner()
        Settlement_Bn()
    }, [selectedDate, selectedDate1])

    const Settlement_Bn = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.rf-rev.com/api/v1/okx/settlement/history`, {
                params: {
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1),
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setSettlement_okx(tmp.curData)
                    setSettlement_pre_okx(tmp.preData)
                }
                setTimeout(() => {
                    setSpinner(false)
                }, 500)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        let v = 0
        let f = 0
        let c = 0
        let sr = 0
        let n = 0

        if (settlement_okx && settlement_okx.length > 0) {
            for (let i = 0; i < settlement_okx.length; i++) {
                let tmp = settlement_okx[i]
                v += Number(tmp.volume)
                f += Number(tmp.fee)
                c += Number(tmp.commission)
                sr += Number(tmp.selferral) + Number(tmp.referral)
                n += Number(tmp.net_profit)
            }
            setTotal([v, f, c, sr, n])
        }
    }, [settlement_okx])

    useEffect(() => {
        let v = 0
        let f = 0
        let c = 0
        let sr = 0
        let n = 0

        if (settlement_pre_okx && settlement_pre_okx.length > 0) {
            for (let i = 0; i < settlement_pre_okx.length; i++) {
                let tmp = settlement_pre_okx[i]
                v += Number(tmp.volume)
                f += Number(tmp.fee)
                c += Number(tmp.commission)
                sr += Number(tmp.selferral) + Number(tmp.referral)
                n += Number(tmp.net_profit)
            }
            setPre_Total([v, f, c, sr, n])
        }
    }, [settlement_pre_okx])

    let slice_data = settlement_okx.slice((page - 1) * 15, (page) * 15)

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        if (settlement_okx) {
            for (let i = 0; i < settlement_okx.length; i++) {
                let t = settlement_okx[i]
                let date = t.date.split('T')
                arr.push({
                    "Date": date ? date[0] : t.date,
                    "Volume": t.volume,
                    "Fee": t.fee,
                    "Commission": t.commission,
                    "Self": t.selferral,
                    "Referral": t.referral,
                    "NetProfit": t.net_profit,
                })
            }
        }

        Excel(arr, "OKX")
    }

    return (
        <div className={`main-box ${darkname}`}>
            <div className={`withdraw-1 ${darkname}`}>
                <div style={{ width: "56px", fontWeight: "bold" }}>{tab === 0 ? "Binance" : tab === 1 ? "OKX" : "Bybit"}</div>
                <div className={`modal-3-1-left ${darkname}`}>
                    <div className={dateBtn === 0 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(0)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => { setSelectedDate(date); setSelectedDate1(date) }}
                        />
                    </div>
                    <div className={dateBtn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(1);
                        setSelectedDate(new Date((new Date()).getTime())); setSelectedDate1(new Date())
                    }}>Today</div>
                    <div className={dateBtn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(2);
                        setSelectedDate(new Date(new Date().getTime() - 604800000)); setSelectedDate1(new Date())
                    }}>7 Days</div>
                    <div className={dateBtn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(3);
                        setSelectedDate(new Date(new Date().getTime() - 2592000000)); setSelectedDate1(new Date())
                    }}>30 Days</div>
                    <div className={dateBtn === 4 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(4);
                        setSelectedDate(new Date(new Date().getTime() - 7776000000)); setSelectedDate1(new Date())
                    }}>90 Days</div>
                    <div className={dateBtn === 5 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(5)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                        <div>~</div>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={selectedDate} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate1}
                            onChange={(date) => setSelectedDate1(date)}
                        />
                    </div>
                </div>
            </div>
            <div className={`reward-box ${darkname}`}>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Volume (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[0]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[0] - pre_total[0] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[0] - pre_total[0] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[0] - pre_total[0]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Fee (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[1]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[1] - pre_total[1] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[1] - pre_total[1] >= 0 ? faCaretUp : faCaretDown} style={{ paddingDown: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[1] - pre_total[1]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Commission (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[2]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[2] - pre_total[2] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[2] - pre_total[2] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[2] - pre_total[2]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Self+Referral</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[3]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[3] - pre_total[3] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[3] - pre_total[3] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[3] - pre_total[3]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Net Profit</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[4]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[4] - pre_total[4] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[4] - pre_total[4] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[4] - pre_total[4]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tradingType-1'>
                <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
            </div>
            <div className='tradingType-2'>
                <div className={`table-head ${darkname}`}>
                    <div>Date</div>
                    <div>Volume</div>
                    <div>Fee</div>
                    <div>Commission</div>
                    <div>Self</div>
                    <div>Referral</div>
                    <div>Net Profit</div>
                </div>
                <div className='table-body' style={{ minHeight: "600px" }}>
                    {
                        spinner
                            ?
                            <div className='spinner-modal'>
                                <SyncLoader color="#F5AC1D" />
                            </div>
                            :
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <SettlementItem key={i} item={a} darkname={darkname} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={settlement_okx} page={page} setPage={setPage} maxFirstPage={15} />
                                </>

                    }
                </div>
            </div>
        </div>
    )
}

function Settlement_Bybit({ tab }) {
    const [page, setPage] = useState(1)
    const [dateBtn, setDateBtn] = useState(0)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [settlement_bn, setSettlement_bn] = useState([])
    const [settlement_pre_bn, setSettlement_pre_bn] = useState([])
    const [spinner, setSpinner] = useState(false)

    const [total, setTotal] = useState([0, 0, 0, 0, 0])
    const [pre_total, setPre_Total] = useState([0, 0, 0, 0, 0])

    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    useEffect(() => {
        onSpinner()
        Settlement_Bn()
    }, [selectedDate, selectedDate1])

    const Settlement_Bn = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.rf-rev.com/api/v1/bybit/settlement/history`, {
                params: {
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1),
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setSettlement_bn(tmp.curData)
                    setSettlement_pre_bn(tmp.preData)
                }
                setTimeout(() => {
                    setSpinner(false)
                }, 500)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        let v = 0
        let f = 0
        let c = 0
        let sr = 0
        let n = 0

        if (settlement_bn && settlement_bn.length > 0) {
            for (let i = 0; i < settlement_bn.length; i++) {
                let tmp = settlement_bn[i]
                v += Number(tmp.volume)
                f += Number(tmp.fee)
                c += Number(tmp.commission)
                sr += Number(tmp.selferral) + Number(tmp.referral)
                n += Number(tmp.net_profit)
            }
            setTotal([v, f, c, sr, n])
        }
    }, [settlement_bn])

    useEffect(() => {
        let v = 0
        let f = 0
        let c = 0
        let sr = 0
        let n = 0

        if (settlement_pre_bn && settlement_pre_bn.length > 0) {
            for (let i = 0; i < settlement_pre_bn.length; i++) {
                let tmp = settlement_pre_bn[i]
                v += Number(tmp.volume)
                f += Number(tmp.fee)
                c += Number(tmp.commission)
                sr += Number(tmp.selferral) + Number(tmp.referral)
                n += Number(tmp.net_profit)
            }
            setPre_Total([v, f, c, sr, n])
        }
    }, [settlement_pre_bn])

    let slice_data = settlement_bn.slice((page - 1) * 15, (page) * 15)

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        if (settlement_bn) {
            for (let i = 0; i < settlement_bn.length; i++) {
                let t = settlement_bn[i]
                let date = t.date.split('T')
                arr.push({
                    "Date": date ? date[0] : t.date,
                    "Volume": t.volume,
                    "Fee": t.fee,
                    "Commission": t.commission,
                    "Self": t.selferral,
                    "Referral": t.referral,
                    "NetProfit": t.net_profit,
                })
            }
        }

        Excel(arr, "Bybit")
    }

    return (
        <div className={`main-box ${darkname}`}>
            <div className={`withdraw-1 ${darkname}`}>
                <div style={{ width: "56px", fontWeight: "bold" }}>{tab === 0 ? "Binance" : tab === 1 ? "OKX" : "Bybit"}</div>
                <div className={`modal-3-1-left ${darkname}`}>
                    <div className={dateBtn === 0 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(0)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => { setSelectedDate(date); setSelectedDate1(date) }}
                        />
                    </div>
                    <div className={dateBtn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(1);
                        setSelectedDate(new Date((new Date()).getTime())); setSelectedDate1(new Date())
                    }}>Today</div>
                    <div className={dateBtn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(2);
                        setSelectedDate(new Date(new Date().getTime() - 604800000)); setSelectedDate1(new Date())
                    }}>7 Days</div>
                    <div className={dateBtn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(3);
                        setSelectedDate(new Date(new Date().getTime() - 2592000000)); setSelectedDate1(new Date())
                    }}>30 Days</div>
                    <div className={dateBtn === 4 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(4);
                        setSelectedDate(new Date(new Date().getTime() - 7776000000)); setSelectedDate1(new Date())
                    }}>90 Days</div>
                    <div className={dateBtn === 5 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(5)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                        <div>~</div>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={selectedDate} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate1}
                            onChange={(date) => setSelectedDate1(date)}
                        />
                    </div>
                </div>
            </div>
            <div className={`reward-box ${darkname}`}>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Volume (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[0]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[0] - pre_total[0] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[0] - pre_total[0] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[0] - pre_total[0]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Fee (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[1]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[1] - pre_total[1] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[1] - pre_total[1] >= 0 ? faCaretUp : faCaretDown} style={{ paddingDown: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[1] - pre_total[1]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Commission (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[2]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[2] - pre_total[2] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[2] - pre_total[2] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[2] - pre_total[2]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Self+Referral</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[3]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[3] - pre_total[3] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[3] - pre_total[3] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[3] - pre_total[3]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Net Profit</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[4]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[4] - pre_total[4] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[4] - pre_total[4] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[4] - pre_total[4]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tradingType-1'>
                <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
            </div>
            <div className='tradingType-2'>
                <div className={`table-head ${darkname}`}>
                    <div>Date</div>
                    <div>Volume</div>
                    <div>Fee</div>
                    <div>Commission</div>
                    <div>Self</div>
                    <div>Referral</div>
                    <div>Net Profit</div>
                </div>
                <div className='table-body' style={{ minHeight: "600px" }}>
                    {
                        spinner
                            ?
                            <div className='spinner-modal'>
                                <SyncLoader color="#F5AC1D" />
                            </div>
                            :
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <SettlementItem key={i} item={a} darkname={darkname} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={settlement_bn} page={page} setPage={setPage} maxFirstPage={15} />
                                </>

                    }
                </div>
            </div>
        </div>
    )
}

function SettlementItem({ item, darkname }) {
    let arr = item.date.split('T')

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td'>{arr[0]}</div>
            <div className='right td'>{changeNum(Number(item.volume).toFixed(5))}</div>
            <div className='right td'>{changeNum(Number(item.fee).toFixed(8))}</div>
            <div className='right td'>{changeNum(Number(item.commission).toFixed(8))}</div>
            <div className='right td'>{changeNum(Number(item.selferral).toFixed(8))}</div>
            <div className='right td'>{changeNum(Number(item.referral).toFixed(8))}</div>
            <div className='right td'>{changeNum(Number(item.net_profit).toFixed(8))}</div>
        </div>
    )
}


const Excel = (excelData, exchange) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `Settlement_${exchange}`;

    const ws = XLSX.utils.aoa_to_sheet([
        ["Date", "Volume", "Fee", "Commission", "Self", "Referral", "Net Profit"]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Date },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Volume },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Fee },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Commission },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Self },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Referral },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.NetProfit }
                ]
            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 80 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 }
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};


function changeTime(d) {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

export default Settlement;