import './css/main.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import { SyncLoader } from "react-spinners";
import NewPage from './NewPage';


function Withdraw() {
    let dark = useSelector((state) => { return state.Darkmode.value });
    let ex_name = useSelector((state) => { return state.ex_name.value });

    const [btn, setBtn] = useState(0)
    const [dateBtn, setDateBtn] = useState(0)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());

    const [search, setSearch] = useState("")
    const [searchbtn, setSearchbtn] = useState(false)
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState(`${ex_name} ID`)
    const [page, setPage] = useState(1)
    const [page1, setPage1] = useState(1)
    const [bn_withdrawData, setBn_WithdrawData] = useState([])
    const [bn_withdrawList, setBn_WithdrawList] = useState([])
    const [okx_withdrawData, setOKX_WithdrawData] = useState([])
    const [okx_withdrawList, setOKX_WithdrawList] = useState([])
    const [bybit_withdrawData, setBybit_WithdrawData] = useState([])
    const [bybit_withdrawList, setBybit_WithdrawList] = useState([])
    const [spinner, setSpinner] = useState(false)


    const darkname = dark ? 'dark' : "";

    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        onSpinner()
        if (btn === 1) {
            OKX_WithdrawData()
        }
        else if (btn === 0) {
            Bn_WithdrawData()
        }
        else {
            Bybit_WithdrawData()
        }
    }, [selectedDate, selectedDate1, btn])

    const Bn_WithdrawData = async (p) => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.rf-rev.com/api/v1/bn/withdrawal/history`, {
                params: {
                    start: selectedDate.getTime(),
                    end: selectedDate1.getTime(),
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setBn_WithdrawData(tmp)
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const OKX_WithdrawData = async (p) => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.rf-rev.com/api/v1/okx/withdrawal/history`, {
                params: {
                    start: selectedDate.getTime(),
                    end: selectedDate1.getTime(),
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setOKX_WithdrawData(tmp)
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const Bybit_WithdrawData = async (p) => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.rf-rev.com/api/v1/bybit/withdrawal/history`, {
                params: {
                    start: selectedDate.getTime(),
                    end: selectedDate1.getTime(),
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setBybit_WithdrawData(tmp)
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        onSpinner()
        let arr = bn_withdrawData
        let filter_bn = []

        if (selectValue == `${ex_name} ID` && search.length > 0) {
            filter_bn = arr.filter((p) => {
                return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
            })
        }
        else if (selectValue == "TXID" && search.length > 0) {
            filter_bn = arr.filter((p) => {
                return p.exuid.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            })
        }
        else if (selectValue == "Amount" && search.length > 0) {
            filter_bn = arr.filter((p) => {
                return String(p.amount).toLocaleLowerCase().includes(search.toLocaleLowerCase())
            })
        }
        else {
            filter_bn = arr
        }
        setBn_WithdrawList(filter_bn)
    }, [bn_withdrawData, searchbtn])

    useEffect(() => {
        onSpinner()
        let arr = okx_withdrawData
        let filter_okx = []

        if (selectValue == `${ex_name} ID` && search.length > 0) {
            filter_okx = arr.filter((p) => {
                return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
            })
        }
        else if (selectValue == "TXID" && search.length > 0) {
            filter_okx = arr.filter((p) => {
                return p.exuid.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            })
        }
        else if (selectValue == "Amount" && search.length > 0) {
            filter_okx = arr.filter((p) => {
                return String(p.amount).toLocaleLowerCase().includes(search.toLocaleLowerCase())
            })
        }
        else {
            filter_okx = arr
        }
        setOKX_WithdrawList(filter_okx)
    }, [okx_withdrawData, searchbtn])

    useEffect(() => {
        onSpinner()
        let arr = bybit_withdrawData
        let filter_bybit = []

        if (selectValue == `${ex_name} ID` && search.length > 0) {
            filter_bybit = arr.filter((p) => {
                return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
            })
        }
        else if (selectValue == "TXID" && search.length > 0) {
            filter_bybit = arr.filter((p) => {
                return p.exuid.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            })
        }
        else if (selectValue == "Amount" && search.length > 0) {
            filter_bybit = arr.filter((p) => {
                return String(p.amount).toLocaleLowerCase().includes(search.toLocaleLowerCase())
            })
        }
        else {
            filter_bybit = arr
        }
        setBybit_WithdrawList(filter_bybit)
    }, [bybit_withdrawData, searchbtn])

    let bn_slice_data = bn_withdrawList.slice((page - 1) * 20, (page) * 20)
    let okx_slice_data = okx_withdrawList.slice((page1 - 1) * 20, (page1) * 20)
    let bybit_slice_data = bybit_withdrawList.slice((page1 - 1) * 20, (page1) * 20)

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setSearchbtn(e => !e)
        }
    };

    return (
        <div className='main'>
            <Sidebar page={1} />
            <div className={`main-box ${darkname}`}>
                <div className='withdraw-1'>
                    <div className='member-1'>
                        <div className={`member-2-search ${darkname}`}>
                            <div className={`member-1-select ${darkname}`} onClick={() => setSelect(e => !e)}>
                                <div className={`member-1-select-1 ${darkname}`}>{selectValue}</div>
                                {
                                    select
                                        ?
                                        <FontAwesomeIcon icon={faSortUp} />
                                        :
                                        <FontAwesomeIcon icon={faSortDown} />
                                }
                                {
                                    select
                                        ? <>
                                            <div className='modal' onClick={() => setSelect(true)}></div>
                                            <div className={`member-1-select-box ${darkname}`}>
                                                <div onClick={() => { setSelectValue(`${ex_name} ID`); setSelect(true) }}>{ex_name} ID</div>
                                                <div onClick={() => { setSelectValue("TXID"); setSelect(true) }}>TXID</div>
                                                <div onClick={() => { setSelectValue("Amount"); setSelect(true) }}>Amount</div>
                                            </div>
                                        </>
                                        :
                                        ""
                                }
                            </div>
                            <FontAwesomeIcon icon={faWindowMinimize} className='line' />
                            <div className={`member-2-search-1 ${darkname}`}>
                                <input value={search} onChange={onChangeSearch} placeholder='Search' onKeyDown={handleKeyPress} />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className='member-1-search-ico-3' />
                                {
                                    search.length > 0
                                        ?
                                        <FontAwesomeIcon icon={faCircleXmark} className='member-1-search-ico-2' onClick={() => setSearch("")} />
                                        :
                                        ""
                                }
                            </div>
                        </div>
                        <div className={`search-btn ${darkname}`} onClick={() => { setSearchbtn(e => !e) }}>Search</div>
                    </div>
                    <div className={`modal-3-1-left ${darkname}`}>
                        <div className={dateBtn === 0 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(0)}>
                            <DatePicker
                                dateFormat='yyyy-MM-dd' // 날짜 형태
                                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                selected={selectedDate}
                                onChange={(date) => { setSelectedDate(date); setSelectedDate1(date) }}
                            />
                        </div>
                        <div className={dateBtn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                            setDateBtn(1);
                            setSelectedDate(new Date()); setSelectedDate1(new Date())
                        }}>Today</div>
                        <div className={dateBtn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                            setDateBtn(2);
                            setSelectedDate(new Date((new Date()).getTime() - 604800000)); setSelectedDate1(new Date())
                        }}>7 Days</div>
                        <div className={dateBtn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                            setDateBtn(3);
                            setSelectedDate(new Date((new Date()).getTime() - 2592000000)); setSelectedDate1(new Date())
                        }}>30 Days</div>
                        <div className={dateBtn === 4 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                            setDateBtn(4);
                            setSelectedDate(new Date((new Date()).getTime() - 7776000000)); setSelectedDate1(new Date())
                        }}>90 Days</div>
                        <div className={dateBtn === 5 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(5)}>
                            <DatePicker
                                dateFormat='yyyy-MM-dd' // 날짜 형태
                                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                            />
                            <div>~</div>
                            <DatePicker
                                dateFormat='yyyy-MM-dd' // 날짜 형태
                                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                minDate={selectedDate} // minDate 이전 날짜 선택 불가
                                maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                selected={selectedDate1}
                                onChange={(date) => setSelectedDate1(date)}
                            />
                        </div>
                    </div>
                </div>
                <div className={`withdraw-2 ${darkname}`}>
                    <div className={btn === 0 ? "on" : ""} onClick={() => setBtn(0)}>Binance</div>
                    <div className={btn === 1 ? "on" : ""} onClick={() => setBtn(1)}>OKX</div>
                    <div className={btn === 2 ? "on" : ""} onClick={() => setBtn(2)}>Bybit</div>
                </div>
                <div className='withdraw-3'>
                    <div className={`table-head ${darkname}`}>
                        <div style={{ width: "70%" }}>Time</div>
                        <div style={{ width: "50%" }}>STATUS</div>
                        <div style={{ width: "50%" }}>{ex_name} ID</div>
                        <div style={{ width: "70%" }}>{btn === 0 ? "Binance UID" : btn === 1 ? "OKX UID" : "Bybit UID"}</div>
                        <div>Wallet</div>
                        <div style={{ width: "50%" }}>Asset</div>
                        <div style={{ width: "50%" }}>Amount</div>
                        <div>TXID</div>
                        <div style={{ width: "70%" }}>IP</div>
                        <div style={{ width: "50%" }}></div>
                    </div>
                    <div className='table-body' style={{ minHeight: "600px" }}>
                        {
                            spinner
                                ?
                                <div className='spinner-modal'>
                                    <SyncLoader color="#F5AC1D" />
                                </div>
                                :
                                btn === 0
                                    ?
                                    bn_withdrawData && bn_withdrawData.length === 0
                                        ?
                                        <div className='data-nofound'>
                                            <img src='/img/img_nofound.png'></img>
                                            <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                            <p>NO RECORDS FOUND.</p>
                                        </div>
                                        :
                                        <>
                                            {
                                                bn_slice_data.map(function (a, i) {
                                                    return (
                                                        <Bn_WithdrawItem key={i} item={a} darkname={darkname} btn={btn} />
                                                    )
                                                })
                                            }
                                            <NewPage initialData={bn_withdrawList} page={page} setPage={setPage} maxFirstPage={20} />
                                        </>
                                    :
                                    btn === 1
                                        ?
                                        okx_withdrawData && okx_withdrawData.length === 0
                                            ?
                                            <div className='data-nofound'>
                                                <img src='/img/img_nofound.png'></img>
                                                <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                <p>NO RECORDS FOUND.</p>
                                            </div>
                                            :
                                            <>
                                                {
                                                    okx_slice_data.map(function (a, i) {
                                                        return (
                                                            <OKX_WithdrawItem key={i} item={a} darkname={darkname} btn={btn} />
                                                        )
                                                    })
                                                }
                                                <NewPage initialData={okx_withdrawList} page={page1} setPage={setPage1} maxFirstPage={20} />
                                            </>
                                        :
                                        bybit_withdrawData && bybit_withdrawData.length === 0
                                            ?
                                            <div className='data-nofound'>
                                                <img src='/img/img_nofound.png'></img>
                                                <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                <p>NO RECORDS FOUND.</p>
                                            </div>
                                            :
                                            <>
                                                {
                                                    bybit_slice_data.map(function (a, i) {
                                                        return (
                                                            <Bybit_WithdrawItem key={i} item={a} darkname={darkname} btn={btn} />
                                                        )
                                                    })
                                                }
                                                <NewPage initialData={bybit_withdrawList} page={page1} setPage={setPage1} maxFirstPage={20} />
                                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function Bn_WithdrawItem({ item, darkname, btn }) {
    const [btn1, setBtn1] = useState(false)
    let tmpdate = item.withdrawal_time.split("T")
    let tmpdate1 = tmpdate[1].split(".000Z")
    let formattedDate = `${tmpdate[0]} ${tmpdate1[0]}`

    const [mouseState, setMouseState] = useState(false);
    const [mouseState1, setMouseState1] = useState(false);

    const onMouseEnter = () => {
        setMouseState(true);
    };
    const onMouseLeave = () => {
        setMouseState(false);
    };
    const onMouseEnter1 = () => {
        setMouseState1(true);
    };
    const onMouseLeave1 = () => {
        setMouseState1(false);
    };

    return (
        <>
            <div className={`table-body-tr ${darkname}`}>
                <div className='td' style={{ width: "70%" }}>{formattedDate}</div>
                <div className='td' style={{ width: "50%" }}>{item.status === 0 ? "Pending" : item.status === 1 ? "Complete" : "Failed"}</div>
                <div className='td' style={{ width: "50%" }}>{item.uid}</div>
                <div className='td' style={{ width: "70%" }}>{item.exuid}</div>
                <div className='overflow-hidden td left' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>{item.wallet ? item.wallet : '-'}
                    {
                        mouseState && item.wallet
                            ?
                            <div className={`overflow-box ${darkname}`} style={{ right: "50%" }}>{item.wallet}</div>
                            :
                            ""
                    }
                </div>
                <div className='td' style={{ width: "50%" }}>{item.asset}</div>
                <div className='right td' style={{ width: "50%" }}>{Number(item.amount)}</div>
                <div className='overflow-hidden td left' onMouseEnter={onMouseEnter1} onMouseLeave={onMouseLeave1}>{item.txid ? item.txid : '-'}
                    {
                        mouseState1 && item.txid
                            ?
                            <div className={`overflow-box ${darkname}`} style={{ right: "19%" }}>{item.txid}</div>
                            :
                            ""
                    }
                </div>
                <div className='td' style={{ width: "70%" }}>{item.ip ? item.ip : '-'}</div>
                <div className='td' style={{ width: "50%" }}>
                    <div className={`withdrawitem-btn ${darkname}`} onClick={() => setBtn1(true)}>Withdrawal</div>
                </div>
            </div>
            {
                btn1
                    ?
                    <ModalWithdraw btn={btn} setBtn1={setBtn1} darkname={darkname} item={item} formattedDate={formattedDate} />
                    :
                    ""
            }
        </>
    )
}

function OKX_WithdrawItem({ item, darkname, btn }) {
    const [btn1, setBtn1] = useState(false)
    let tmpdate = item.withdrawal_time.split("T")
    let tmpdate1 = tmpdate[1].split(".000Z")
    let formattedDate = `${tmpdate[0]} ${tmpdate1[0]}`

    const [mouseState, setMouseState] = useState(false);
    const [mouseState1, setMouseState1] = useState(false);

    const onMouseEnter = () => {
        setMouseState(true);
    };
    const onMouseLeave = () => {
        setMouseState(false);
    };
    const onMouseEnter1 = () => {
        setMouseState1(true);
    };
    const onMouseLeave1 = () => {
        setMouseState1(false);
    };

    return (
        <>
            <div className={`table-body-tr ${darkname}`}>
                <div className='td' style={{ width: "70%" }}>{formattedDate}</div>
                <div className='td' style={{ width: "50%" }}>{item.status === 0 ? "Pending" : item.status === 1 ? "Complete" : "Failed"}</div>
                <div className='td' style={{ width: "50%" }}>{item.uid}</div>
                <div className='td' style={{ width: "70%" }}>{item.exuid}</div>
                <div className='overflow-hidden td left' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>{item.wallet ? item.wallet : '-'}
                    {
                        mouseState && item.wallet
                            ?
                            <div className={`overflow-box ${darkname}`} style={{ right: "50%" }}>{item.wallet}</div>
                            :
                            ""
                    }
                </div>
                <div className='td' style={{ width: "50%" }}>{item.asset ? item.asset : '-'}</div>
                <div className='right td' style={{ width: "50%" }}>{item.amount ? Number(item.amount) : '-'}</div>
                <div className='overflow-hidden td left' onMouseEnter={onMouseEnter1} onMouseLeave={onMouseLeave1}>{item.txid ? item.txid : '-'}
                    {
                        mouseState1 && item.txid
                            ?
                            <div className={`overflow-box ${darkname}`} style={{ right: "19%" }}>{item.txid}</div>
                            :
                            ""
                    }
                </div>
                <div className='td' style={{ width: "70%" }}>{item.ip ? item.ip : '-'}</div>
                <div className='td' style={{ width: "50%" }}>
                    <div className={`withdrawitem-btn ${darkname}`} onClick={() => setBtn1(true)}>Withdrawal</div>
                </div>
            </div>
            {
                btn1
                    ?
                    <ModalWithdraw btn={btn} setBtn1={setBtn1} darkname={darkname} item={item} formattedDate={formattedDate} />
                    :
                    ""
            }
        </>
    )
}

function Bybit_WithdrawItem({ item, darkname, btn }) {
    const [btn1, setBtn1] = useState(false)
    let tmpdate = item.withdrawal_time.split("T")
    let tmpdate1 = tmpdate[1].split(".000Z")
    let formattedDate = `${tmpdate[0]} ${tmpdate1[0]}`

    const [mouseState, setMouseState] = useState(false);
    const [mouseState1, setMouseState1] = useState(false);

    const onMouseEnter = () => {
        setMouseState(true);
    };
    const onMouseLeave = () => {
        setMouseState(false);
    };
    const onMouseEnter1 = () => {
        setMouseState1(true);
    };
    const onMouseLeave1 = () => {
        setMouseState1(false);
    };

    return (
        <>
            <div className={`table-body-tr ${darkname}`}>
                <div className='td' style={{ width: "70%" }}>{formattedDate}</div>
                <div className='td' style={{ width: "50%" }}>{item.status}</div>
                <div className='td' style={{ width: "50%" }}>{item.uid}</div>
                <div className='td' style={{ width: "70%" }}>{item.exuid}</div>
                <div className='overflow-hidden td left' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>{item.wallet ? item.wallet : '-'}
                    {
                        mouseState && item.wallet
                            ?
                            <div className={`overflow-box ${darkname}`} style={{ right: "50%" }}>{item.wallet}</div>
                            :
                            ""
                    }
                </div>
                <div className='td' style={{ width: "50%" }}>{item.asset ? item.asset : '-'}</div>
                <div className='right td' style={{ width: "50%" }}>{item.amount ? Number(item.amount) : '-'}</div>
                <div className='overflow-hidden td left' onMouseEnter={onMouseEnter1} onMouseLeave={onMouseLeave1}>{item.txid ? item.txid : '-'}
                    {
                        mouseState1 && item.txid
                            ?
                            <div className={`overflow-box ${darkname}`} style={{ right: "19%" }}>{item.txid}</div>
                            :
                            ""
                    }
                </div>
                <div className='td' style={{ width: "70%" }}>{item.ip ? item.ip : '-'}</div>
                <div className='td' style={{ width: "50%" }}>
                    <div className={`withdrawitem-btn ${darkname}`} onClick={() => setBtn1(true)}>Withdrawal</div>
                </div>
            </div>
            {
                btn1
                    ?
                    <ModalWithdraw btn={btn} setBtn1={setBtn1} darkname={darkname} item={item} formattedDate={formattedDate} />
                    :
                    ""
            }
        </>
    )
}

function ModalWithdraw({ btn, setBtn1, darkname, item, formattedDate }) {
    let ex_name = useSelector((state) => { return state.ex_name.value });
    
    const [type, setType] = useState(false)
    const [depositData, setDepositData] = useState([])

    const handleEsc = (event) => {
        if (event.key === 'Escape') {
            setBtn1(false);
        }
    };
    document.addEventListener('keydown', handleEsc);

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            DepositData(token)
        }
    }, [])

    const DepositData = async (token) => {
        await axios.get(`https://api.admin.rf-rev.com/api/v1/${btn === 0 ? "bn" : btn === 1 ? "okx" : "bybit"}/user/deposit/history`, {
            params: {
                start: (new Date()).getTime() - 2592000000,
                end: new Date().getTime(),
                uid: item.exuid
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data
            if (tmp.code === 1000) {
                setDepositData(tmp.data ?? [])
            }
        }).catch(error => {
            console.error(error);
        });

    }

    const onConfirmWithdraw = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.rf-rev.com/api/v1/${btn === 0 ? "bn" : "okx"}/withdrawal`, {
                params: {
                    widId: item.id
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const onRejectWithdraw = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.rf-rev.com/api/v1/${btn === 0 ? "bn" : "okx"}/withdrawal/reject`, {
                params: {
                    widId: item.id
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
            }).catch(error => {
                console.error(error);
            });
        }
    }

    return (
        <div className='modal'>
            <div className='modal-box'>
                <div className='modal-1'>
                    <div className='modal-1-tit-1'>
                        <div>Withdraw</div>
                        <div className={`modal-1-tit-box ${darkname}`}>{ex_name} ID : {item.uid}</div>
                        <div className={`modal-1-tit-box ${darkname}`}>Status : {item.status}</div>
                        <div className={`modal-1-tit-box ${darkname}`}>Time : {formattedDate}</div>
                        <FontAwesomeIcon icon={faXmark} className='modal-x' onClick={() => setBtn1(false)} />
                    </div>
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-1-tit'>Asset</div>
                            <div className='modal-1-1-1-txt'>{item.asset ? item.asset : '-'}</div>
                            <div className='modal-1-1-1-tit'>Amount</div>
                            <div className='modal-1-1-1-txt'>{item.amount ? Number(item.amount) : '-'}</div>
                        </div>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-2-tit'>{btn === 0 ? "Binance UID" : btn === 1 ? "OKX UID" : "Bybit UID"}</div>
                            <div className='modal-1-1-2-txt'>{item.exuid}</div>
                        </div>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-2-tit'>TXID</div>
                            <div className='modal-1-1-2-txt'>{item.txid ? item.txid : '-'}</div>
                        </div>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-2-tit'>wallet</div>
                            <div className='modal-1-1-2-txt'>{item.wallet ? item.wallet : '-'}</div>
                        </div>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-2-tit'>IP</div>
                            <div className='modal-1-1-2-txt'>{item.ip ? item.ip : '-'}</div>
                        </div>
                    </div>
                    {
                        item && item.status === 0
                            ?
                            <div className='modal-1-3'>
                                <div className='modal-1-3-1' onClick={() => { onConfirmWithdraw() }}>Confirm</div>
                                <div className='modal-1-3-1' onClick={() => { onRejectWithdraw() }}>Reject</div>
                            </div>
                            :
                            <div className='modal-1-3'>
                                <div className='modal-1-3-1 complete'>Complete</div>
                            </div>
                    }
                    {/* <div className={`hr ${darkname}`}></div> */}
                    {
                        depositData && depositData.length === 0
                            ?
                            ""
                            :
                            <div className='modal-1-4'>
                                <div className='modal-1-tit-1' style={{ marginBottom: "0px" }}>
                                    <div>Deposit History</div>
                                </div>
                                <div className={`table-head ${darkname}`}>
                                    <div>Date</div>
                                    <div>TX</div>
                                    <div>Amount</div>
                                    <div>Asset</div>
                                    <div>Network</div>
                                    <div>Wallet</div>
                                </div>
                                <div className='table-body' style={{ marginBottom: "20px" }}>
                                    {
                                        depositData.map(function (a, i) {
                                            if (i <= 3) {
                                                return (
                                                    <DepositItem key={i} item={a} darkname={darkname} btn={btn} />
                                                )
                                            }
                                        })
                                    }
                                </div>
                                <div className='modal-1-3'>
                                    <div className='modal-1-3-1' onClick={() => { window.open(`/member`) }}>See More</div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

function DepositItem({ item, darkname }) {
    let tmpdate = item.deposit_time
    let formattedDate = `${tmpdate[0]}.${padZero(tmpdate[1])}.${padZero(tmpdate[2])} ${padZero(tmpdate[3])}:${padZero(tmpdate[4])}:${tmpdate[5] ? padZero(tmpdate[5]) : "00"}`;

    return (
        <>
            <div className={`table-body-tr ${darkname}`}>
                <div className='td'>{formattedDate}</div>
                <div className='overflow-hidden td'>{item.txid}</div>
                <div className='td'>{item.amount}</div>
                <div className='td'>{item.asset}</div>
                <div className='td'>{item.network}</div>
                <div className='overflow-hidden td'>{item.wallet}</div>
            </div>
        </>
    )
}

function padZero(value) {
    return value < 10 ? `0${value}` : value;
}

function changeTime(d) {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

export default Withdraw;