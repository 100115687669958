import './App.css';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import Header from './Nav/header'
import Footer from './Nav/footer'
import Login from './Pages/login'
import Dashboard from './Pages/dashboard'
import Member from './Pages/member'
import Verification from './Pages/verification'
import PasswordReset from './Pages/passwordReset'
import Withdraw from './Pages/withdraw'
import Trading from './Pages/trading'
import TradingType from './Pages/tradingType'
import Reward from './Pages/reward'
import Settlement from './Pages/settlement'
import Setting from './Pages/setting'
import Cslog from './Pages/cslog'
import Profile from './Pages/profile'
import Board from './Pages/board'
import Notice from './Pages/notice'

import ErrorBoundary from './Pages/common/ErrorBoundary'

// Admin
import Admin_Dashboard from './Pages/admin/admin_dashboard';
import Admin_Day_Settlement from './Pages/admin/admin_day_settlement';
import Admin_Settlement from './Pages/admin/admin_settlement';
import Admin_Referral_List from './Pages/admin/admin_referral_list';
import Admin_Trading_History from './Pages/admin/admin_trading_history';
import Admin_Withdraw_History from './Pages/admin/admin_withdraw_history';
import Admin_Transfer_History from './Pages/admin/admin_transfer_history';

function App() {
  console.error = (message) => {
    // if (message.startsWith('Warning: ...')) {
    //   return; // 특정 경고 무시
    // }
    // originalConsoleError(message);
  };

  return (
    <div className="App">
      <Header />
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/passwordReset" element={<PasswordReset />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/member" element={<Member />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/trading" element={<Trading />} />
          <Route path="/tradingType" element={<TradingType />} />
          <Route path="/reward" element={<Reward />} />
          <Route path="/settlement" element={<Settlement />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/cslog" element={<Cslog />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/board" element={<Board />} />
          <Route path="/notice" element={<Notice />} />

          <Route path="/admin_dashboard" element={<Admin_Dashboard />} />
          <Route path="/admin_day_settlement" element={<Admin_Day_Settlement />} />
          <Route path="/admin_settlement" element={<Admin_Settlement />} />
          <Route path="/admin_referral_list" element={<Admin_Referral_List />} />
          <Route path="/admin_trading_history" element={<Admin_Trading_History />} />
          <Route path="/admin_withdraw_history" element={<Admin_Withdraw_History />} />
          <Route path="/admin_transfer_history" element={<Admin_Transfer_History />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ErrorBoundary>
      <Footer />
    </div>
  );
}

export default App;
