import './css/main.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import Page from './Page'
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import { removeZeros, changeNum } from './common/function'
import axios from 'axios';

let maxFirstPage = 15;
let pageData = []

function Trading() {
    const [page, setPage] = useState(1)
    const [btn, setBtn] = useState(0)
    const [data, setData] = useState([])
    const [series, setSeries] = useState([0, 0, 0])

    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    let arr = [1, 2, 3, 4, 5, 6, 7, 8]

    pageData = arr.slice((page - 1) * maxFirstPage, (page) * maxFirstPage)

    const options = {
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: ['Binance', 'OKX', 'Bybit'],
        colors: ['#fcd535', '#777777', '#f4a203'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    useEffect(() => {
        OverviewData()
    }, [btn])

    const OverviewData = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.rf-rev.com/api/v1/admin/trade/overview`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    if (btn === 1) {
                        setSeries([Number(tmp?.bn?.spot_volume ?? 0), Number(tmp?.okx?.spot_volume ?? 0), Number(tmp?.bybit?.spot_volume ?? 0)])
                    }
                    else if (btn === 2) {
                        setSeries([Number(tmp?.bn?.futures_volume ?? 0), Number(tmp?.okx?.futures_volume ?? 0), Number(tmp?.bybit?.futures_volume ?? 0)])
                    }
                    else {
                        setSeries([Number(tmp?.bn?.total_volume ?? 0), Number(tmp?.okx?.total_volume ?? 0), Number(tmp?.bybit?.total_volume ?? 0)])
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    return (
        <div className='main'>
            <Sidebar page={2} />
            <div className={`main-box ${darkname}`}>
                <div className={`withdraw-2 ${darkname}`}>
                    <div className={btn === 0 ? "on" : ""} onClick={() => setBtn(0)}>Overview</div>
                    <div className={btn === 1 ? "on" : ""} onClick={() => setBtn(1)}>Spot</div>
                    <div className={btn === 2 ? "on" : ""} onClick={() => setBtn(2)}>Futures</div>
                </div>
                <div className='trading-1'>
                    <div className={`trading-1-1 ${darkname}`}>
                        <Chart options={options} series={series} type="pie" width={400} />
                    </div>
                    <div className='trading-1-2'>
                        <Link to={`/tradingType`} state={{ num: 0 }} style={{ width: "100%" }}>
                            <div className={`trading-1-2-box ${darkname}`}>
                                <div className='trading-1-2-box-1'>
                                    <img src='/img/binance.png' height={25} />
                                </div>
                                <div className={`trading-1-2-box-2 ${darkname}`}>{changeNum(Number(series[0]).toFixed(2))}<span>USDT</span></div>
                            </div>
                        </Link>
                        <Link to={`/tradingType`} state={{ num: 1 }} style={{ width: "100%" }}>
                            <div className={`trading-1-2-box ${darkname}`}>
                                <div className='trading-1-2-box-1'>
                                    {
                                        dark
                                            ?
                                            <img src='/img/okx_dark.png' height={18} style={{ paddingTop: "2px" }} />
                                            :
                                            <img src='/img/okx.png' height={18} style={{ paddingTop: "2px" }} />
                                    }
                                </div>
                                <div className={`trading-1-2-box-2 ${darkname}`}>{changeNum(Number(series[1]).toFixed(2))} <span>USDT</span></div>
                            </div>
                        </Link>
                        <Link to={`/tradingType`} state={{ num: 2 }} style={{ width: "100%" }}>
                            <div className={`trading-1-2-box ${darkname}`}>
                                <div className='trading-1-2-box-1'>
                                    {
                                        dark
                                            ?
                                            <img src='/img/bybit_dark.png' height={22} />
                                            :
                                            <img src='/img/bybit.png' height={22} />
                                    }
                                </div>
                                <div className={`trading-1-2-box-2 ${darkname}`}>{changeNum(Number(series[2]).toFixed(2))} <span>USDT</span></div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trading;